import "./base.css";
import { useMediaQuery } from 'react-responsive';


function Base() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
      <h1 className="h1">الأساس</h1>
      <br />
      <h2 className="h2">ما الذي تحتويه هذه الفقرة؟</h2>
      <p className="p">
      لتعلم أي لغة، يجب معرفة بعض الأساسيات مثل تركيب الجمل والمفردات والجمع والأزمنة (الماضي، الحاضر، والمستقبل)، وما إلى ذلك. في هذه الفقرة، ستجد رابطًا إلى مجموعة مختارة بعناية من الفيديوهات التي لا تكون مملة، والمطلوب منك هو مشاهدة كل يوم فيديو من هذه السلسلة،  ليس ضروري الحفظ والكتابة، لأن  القواعد ستكتسبها من خلال الاستماع المتكرر للفرنسية، كما سأوضح لك بالتفصيل في فقرة الاستماع. المهم في هذه الفقرة هو مشاهدة كل فيديو من البداية إلى النهاية بتركيز تام.
      </p>
      <br />
      <h2 className="h2">لماذا أحتاج إلى مشاهدة هذه الفيديوهات إذا كنت سأتعلم القواعد من خلال الاستماع؟</h2>
      <p className="p">هذه السلسلة مصممة فقط لتعريفك بالقواعد التي ستسرع من تطورك في اللغة الفرنسية، وستمكنك أيضًا من البدء في التحدث من اليوم الأول، كما سأوضح بالتفصيل في فقرة التحدث.</p>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/lNhqDCcGFXE?si=VtYahQY4kW9ea4Ew" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <a href="https://www.youtube.com/playlist?list=PLm_0RcffDn4FtnR1LAn0c90VhJNhWc65f">سلسلة القواعد : اضغط هنا</a>
      <br /><br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/LhIJqY5pp6Q?si=42UqRHrxScu_NSHy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
    </div>
  );
}

export default Base;