import "./read.css";
import { useMediaQuery } from 'react-responsive';


function Read() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
      <h1 className="h1">القراءة</h1>
      <p className="p">القراءة تساعدك في معرفة كيف تركب الجمل، وكيف تطبق القواعد، وتعلمك أن تفهم معاني الكلمات من السياق ... .القراءة يمكنها تسريع من تعلمك للغة الفرنسية، إن مارستها بشكل صحيح، فيجب أن تقرأ ما تفهم، يعني عندما تنتهي من القراءة يجب أن تكون فهمت على الأقل 70%. وأنصح بالبدأ بقراءة القصص لأنها تكون سهلت الفهم، وتحتوي على الأزمنة الثلاثة، وتكون سهلت التذكر، مما تمكنك من تذكر كلمات دون حفظها.</p>
      <h2 className="h2">ماذا وكيف أقرأ؟</h2>
      <br />
      <p className="p">في هذا الفديو سأشرح لك كيف تقرأ وما تقرأ حسب مستواك</p>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/22TEtHLGkUw?si=66ibKlA46n15FmdL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <a href="https://freekidsbooks.org/subject/files/foreign-language/french/">موقع فيه كتب بالفرنسية للقراءة والتحميل : اضغط هنا</a>
      <br /><br />
      <a href="https://www.ebookids.com/">موقع فيه قصص بالفرنسية للقراءة أونلاين : اضغط هنا</a>
      <br /><br />
      <a href="https://play.google.com/store/apps/details?id=com.bthr.story2&pcampaignid=web_share">تحميل تطبيق فيه قصص باللغة الفرنسية للقراءة : اضغط هنا</a>
      <br /><br />
      <a href="https://play.google.com/store/apps/details?id=net.goldensoft.learnstoryfrar&pcampaignid=web_share">تحميل تطبيق فيه قصص بالفرنسية مع الترجمة للعربية : اضغط هنا</a>
      <br /><br />
      <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
    </div>
  );
}

export default Read;