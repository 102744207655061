import Logo from './../logo/Logo'
import { useEffect, useState } from 'react'
import axios from 'axios'; // Import Axios
import { useNavigate} from "react-router-dom";

function Login(){
    const navigate = useNavigate();

    const [csvData, setCsvData] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior

        // Find the user in the csvData object
        const user = csvData.find((user) => user.mail === email && user.password === password);

        // Check if the user exists
        if (user) {
            // User found, do something (e.g., redirect to another page)
            setErrorMessage('');
            navigate("/introduction");
        } else {
            // User not found or incorrect password
            setErrorMessage('Invalid email or password!');
        }
    };

    useEffect(() => {
        fetchCSVData();    // Fetch the CSV data when the component mounts
    }, []); // The empty array ensures that this effect runs only once, like componentDidMount

    const fetchCSVData = () => {
    const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQMkqe9getP4u9UocyWzpcfpb126u0ZcdNoOAH8YxZQMGsTJ4iCmGdVLOWLIzlhYuWUBD6jPgs2ZxNN/pub?output=csv'; //'https://docs.google.com/spreadsheets/d/e/2PACX-1vRZRn068E9pebrup1AYQm158we5GN8IOLauAPUeGXqevs4jvQ82eBu4jAjlaYgaY8NSJz-hG2xaZeEG/pub?output=tsv' // Replace with your Google Sheets CSV file URL

        axios.get(csvUrl)    // Use Axios to fetch the CSV data
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);        // Parse the CSV data into an array of objects
                setCsvData(parsedCsvData);        // Set the fetched data in the component's state
                console.log(parsedCsvData);        // Now you can work with 'csvData' in your component's state.
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    }

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
        const headers = rows[0].split(',');        // Extract headers (assumes the first row is the header row)
        const data = [];        // Initialize an array to store the parsed data
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
            }
            data.push(rowObject);
        }
        return data;
    }
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Logo />
                <h2 className="mt-10  t-color text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                الدخول إلى حسابك 
                </h2>
            </div>
  
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block t2-color text-sm font-medium leading-6 text-gray-900">
                         عنوان البريد الإلكتروني
                        </label>
                        <div className="mt-2">
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
    
                    <div>
                        <div className="">
                            <label htmlFor="password" className="block t2-color text-sm font-medium leading-6 text-gray-900">
                            كلمة المرور
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                id="password"
                                name="password"
                                type="text"
                                autoComplete="current-password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                    <div>
                        <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        تسجيل الدخول
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
  }
  
export default Login;