import "./intro.css";
import { useMediaQuery } from 'react-responsive';



function Intro() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
    <h1 className="h1"> مقدمة  </h1>
    <h2 className="h2">من نحن؟</h2>
    <p className="p">
        أنا مثلكم مغربي درست في التعليم العمومي لمدة 12 سنة ولم أكون أستطيع حتى قول جملة مفيدة باللغة الفرنسية,
         ولاكن عندما انتقلت الى التعليم الجامعي كان لابد من تعلم اللغة الفرنسية لأن الدروس كلها كانت باللغة الفرنسية
          وعندي محضرات أيضا باللغة الفرنسية, حينها قررت البحث عن طريقة أو لنقل أسرع طريقة لتعلم اللغة الفرنسية
           والتكلم بها بشكل جيد, ولكي أجد هذه الطريقة استغرق مني الأمر ثلاث سنوات من البحث والقراءة, وبعد أن استعملت هذه
            الطريقة لمدة ستة أشهر استطعت أن انتقل من شخص لا يستطيع حتى أن يقول جملة مفيدة باللغة الفرنسية الى شخص
             يستطيع أن يتكلم مع الناطقين بهذه اللغة بدون مشاكل.
    </p>
    <h2 className="h2">لماذا هذه الطريقة؟</h2>
    <p className="p">
        هذه الطريقة ستجعلكم تفكرون باللغة الفرنسية وتتكلمونها بشكل صحيح وسلس وبدون مجهود, كل ما تحتجونه هو الصبر 
        والمداومة والاستمرار وتتبع كل ما سأقوله بدقة وتركيز.
    </p>
    <h2 className="h2">الطريقة؟</h2>
    <p className="p">
        بهذه الطريقة تعلمنا لغتنا الأم, فعندما كنا صغار كنا  نسمع كثيرا وبشكل متكرر وبعدها نتكلم بما سمعناه
         ونكرره حتى ننطقه بالشكل الصحيح, وهكذا ..., هذه الطريقة فهي تعتمد عن الاستماع والتكلم والتكرار المتباعد, اضافة
          الى القراءة والكتابة لمن عنده وقت ويريد أن يتعلم بسرعة, وطبعا أهم شيء هو المداومة والاستمرار.
        <br/><br/>
        هذا الطريقة مقسمة كالتالي:
        
        <ul>
            <li className="li"><b>البداية</b>: هنا سوف تتعلم 1000 كلمة وجملة</li> 
            <li className="li"><b>الاساس</b>: هنا سوف تتعلم كيف تكوّن الجمل البسيطة</li> 
            <li className="li"><b>الاستماع</b>: هنا سوف تسمع شيء تفهمه حسب مستواك</li>
            <li className="li"><b>التكلم</b>: هنا سوف تتكلم حسب مستواك</li>
            <li className="li"><b>القراءة</b>: قراءة شيء تفهمه حسب مستواك</li>
            <li className="li"><b>الكتابة</b>: كتابة فقرات حسب مستواك</li>
        </ul>
    </p>
    <h2 className="h2">لنبدأ ؟</h2>
    <p className="p">
        الان ستبدأ الرحلة لتعلم اللغة الفرنسية, فكلما تحتاجه هو هاتف أو حاسوب وانترنت وربما ستحتاج الى دفتر للكتابة.
        قبل أن نبدأ فالمداومة و الاستمرار مهمان جدا في تعلم اللغة, وحاول قدر الامكان ان تغير لغتك الى اللغة الفرنسية
        فمثلا غير اعدادات الهاتف الى الفرنسية. الحاسوب الى الفرنسية, التطبيقات الى الفرنسية, الالعاب الى الفرنسية وهكذا ...
    </p>
    <br/>
    <br/>
    <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/AGT0zWDIqCA?si=y09JbKc_OtmE7ij_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <br/>
    <br/>
    <p className="p">الان انتقل الى فقرة البداية</p>
    <br />
    <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
</div>
  );
}

export default Intro;