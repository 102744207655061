import "./button.css";


function Button({ texte, icon ,handleClick,active}) {
  const logo = require(`${icon}`);
  const id = "btn"+texte;
  const handleActive = () => {
    if(active){
      document.getElementById(id).classList.add("button-active");
      document.getElementById(id).classList.remove("button");
      
    }
    
  }
  
  return (
    <div 
      id={id}
      className="button grid grid-cols-7 gap-0"
      onClick={handleClick} 
      onLoad={handleActive}
    >
      
      <div className="col-span-5" dir="rtl">
        <h1 className="title-btn-etu">{texte}</h1>
      </div>

      <div className="col-span-2 ">
        <img src={logo} className="icon-btn-etu" />
      </div>

    </div>
  );
}

export default Button;
