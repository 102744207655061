import "./logo.css";

function Logo() {
  return (
    <div className="logo">
        
    </div>
  );
}

export default Logo;
