import "./speak.css";
import { useMediaQuery } from 'react-responsive';


function Speak() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
      <h1 className="h1">التحدث</h1>
      <br />
      <p className="p">لكي تتكلم أي اللغة يجب أن تبدأ التكلم من البداية، لا تقول حتى أحفظ الكلمات الأساسية وأفهم القواعد و.... ثم سأبدأ بالتحدث بالفرنسية،  لن تتحدث بالفرنسية أبدا بهذه العقلية، أنا عندي أصدقائي درسو في مدارس خصوصية، أي كلمة بالفرنسية يعرفون معناها، ولاكن عندما يطلب منهم أن يتكلم بالفرنسية، العديد منهم لا يستطيع أن يتكلم بدون أخطاء، بدون تلعثم، تجدهم يتعذبون وهم يتكلمون، لأنهم لم يبدأ بالتحدث من البداية. </p>
      <br />
      <p className="p">فنرجع إلى مثال محمد الذي تحثنا عليه في الفقرة السابقة، فمحمد عندما ذهب إلى تلك الدولة الفرنسية، بدأ التكلم بالفرنسية من يومه الأول سواء في المتجر، في الشارع أو مع أصدقائه، لذلك فقط في ست أشهر أو أقل أصبح يتحدث الفرنسية بدون تلعثم، بدون جهد، بدون أخطاء وبشكل عادي، كأنه يتكلم لغته الأم.</p>
      <br />
      <p className="p">فإذ كنت تريد أن تتكلم اللغة الفرنسية كما تتكلم لغتك الأم فعليك أن تبدأ من اليوم بالتكلم بالفرنسية.</p>
      <h2 className="h2">مع من وكيف أتكلم وأنا في دولة عربية؟</h2>
      <p className="p">أنا عندي لك ثلاث طرق ناجحة في اي دولة كنت : شاهد الفديو بتركيز تام.</p>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/azLiuOOVPKc?si=q35ENa5i_sLyrb_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <a href="https://youtu.be/h3zQT8i97Uk?si=VBtZ_i47hNJPLNnO">هاذا مثال لاستخدام ChatGPT لممارسة الإنجليزية، يعني أنت تفعل نفس الشيء لتمارس اللغة الفرنسية(اضغط هنا لمشاهدة الفديو)</a>
      <br /><br />
      <a href="https://gliglish.com/free">اضغط هنا لممارسة الفرنسية مع Giglish طبعا هو جيد جدا للمبتدئين لأنه مثل الواتساب وفيه ميزات أخرى.</a>
      <br />
      <br />
      <a href="https://drive.google.com/file/d/1KsGEPTHUvNs350rdKzjhvrv-bJsAl2Np/view?usp=drive_link">اضغط هنا لتحميل المواضيع التي يمكنك التحدث فيها</a>
      <br /><br />
      <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
</div>
  );
}

export default Speak;