import './navbar.css'
import logo from './logo.svg'
import close from './close.svg'
import menu from './menu.svg'
import { useMediaQuery } from 'react-responsive'
function Navbar({handleMenuClick, menuBtn, closeBtn, handleCloseClick}){
    const isLaptop = useMediaQuery({ query: '(min-width: 781px)' })
    const display = isLaptop && "hidden"
    return(
        <nav className={"flex main-nav w-full justify-around pt-2 items-center " + display}>
            <img src={logo} className="icon-nav-btn cursor-pointer" />
            <div className="h1-nav cursor-pointer">EDUPLUS</div>
            <img src={menu} onClick = {handleMenuClick} className={"icon-nav-btn cursor-pointer " + menuBtn} />
            <img src={close}  onClick={handleCloseClick} className={"icon-nav-btn cursor-pointer " + closeBtn}/>
        </nav>
    )
}

export default Navbar;