import "./listen.css";
import { useMediaQuery } from 'react-responsive';


function Listen() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
      <h1 className="h1"> الاستماع  </h1>
      <br />
      <h2 className="h2">لماذا الإستماع هو المفتاح الوحيد لتعلم أي لغة؟</h2>
      <p className="p">
      تخيل معي شخص إسمه محمد ذهب إلى فرنسا أو إلى أي دولة تتحدث الفرنسية، كم يحتاج من الوقت لتعلم اللغة الفرنسية والتحدث بها بشكل جيد جدا؟ الجواب : أقل من ستة أشهر. لماذا؟ لأنه كل يوم سيسمع الفرنسية سواء في الشارع أو من الأصدقاء أو في المتجر ...، وأيضا كل يوم يحاول التكلم بالفرنسية سواء عند المتجر لشراء شيء ما أو مع أصدقائه ... . 
      </p>
      <p className="p">
      هكذا حتى أنت يمكنك أن تتعلم الفرنسية وتتحدث بها بشكل جيد في أقل من ستة أشهر إذا تتبعت نفس الطريقة ونفس الخطوات. يعني يجب عليك أن تخصص وقت كل يوم لكي تستمع للغة الفرنسية، تستمع لشيء تفهمه، سواء كانت أغنية، فيديوهات على يوتيوب، أفلام، كرتون ...، المهم هو أن تسمع ما تفهم أو على الأقل تفهم منه 70%.
      </p>
      <h2 className="h2">كيف أسمع ما أفهم وأنا أصلا مبتدئ لا أفهم شيء في اللغة الفرنسية؟</h2>
      <p className="p">
      لا تتسرع، كل مشكل له حل، كلنا بدأنا من الصفر. ستبدأ أولا بالإستماع لفديوهات مخصصة للمبتدئين لتعود أذنك على الإستماع للفرنسية، في هذه الفديوهات ستسمع  فيها قصص كلماتها سهلت، جملها بسيطة، كذلك تكون الجمل التي تسمعها ظاهرة على الفديو بالفرنسية مع ترجمتها بالعربية. وطبعا ستجدون رابط في الأسفل فيه فديوهات للاستماع حسب مستواك. 
      </p>
      <p className="p">
      الآن شاهد الفديو الذي سأشرح لك فيه ما هي أفضل طريقة للإستماع لتخرج بأكبر فائدة من الإستماع و بالتالي تطور لغتك الفرنسية بشكل سريع جدا.
      </p>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/GjxtvjMjicc?si=2hys5RTAQyCguqko" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <a href="https://youtube.com/playlist?list=PLm_0RcffDn4EuoFEOqPL2PzDETNHlM7W8&si=EYibCJAAjYpEFWs-">اضغط هنا : سلسلة الاستماع</a>
      <br />
    <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
    </div>
  );
}

export default Listen;