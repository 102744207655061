import Logo from "./../logo/Logo";
import './home.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate} from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const buyBtnPhone = isPhone? " buy-btn-phone":"";
  const h1MainPhone = isPhone? " h1-main-phone":"";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  const pMainPhone = isPhone? " p-main-phone":"";
  const mainTextPhone = isPhone? " main-text-phone":"";

  const handleWhatsAppClick = () => {
    // Change the phone number and message as needed
    const phoneNumber = '+212642366594';
    const message = 'Bonjour, ';
    
    // Construct the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, '_blank');
  };

  const handleSignupClick = () => {
    window.location.href = 'https://forms.gle/nJR5RPAV99o6seeC9';
  };
  const handleLoginClick = () => {
    navigate("/login");
  };
  return (
    <div className="">
        <div className="flex justify-center mt-10">
                <Logo />
        </div>
        <div className="mx-28 ">
            <h1 className={"h1-main" + h1MainPhone}> من شخص لا يستطيع أن يقول جملة مفيدة بالفرنسية إلى شخص يتكلم ويفكر بالفرنسية بشكل جيد وبدون مجهود</h1>
        </div>
        <div className="flex justify-center">
          <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/NdlS6pvB71Q?si=Gr_ubINKXhOahGcp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div className={"main-text" + mainTextPhone}>
            <p className={"p-main" + pMainPhone}>تعلم اللغة الفرنسية في أي وقت يناسبك بالإنترنت وبدون إنترنت. نحن هنا لنقدم لك خطة تعلم مبسطة وفعّالة. ستبدأ في التحدث بالفرنسية من اليوم الأول، وستلاحظ النتائج المذهلة خلال أشهر قليلة .</p>
            <br />
            <p className={"p-main" + pMainPhone}> هذه الطريقة تركز على مهارات الاستماع والتحدث دون الحاجة إلى حفظ القواعد. بفضل هذه الطريقة، ستتحدث اللغة الفرنسية بشكل جيد وبدون مجهود.</p>
            <br />
            <p className={"p-main" + pMainPhone}>هذه الطريقة تطلبت مني أكثر من 3 سنوات من البحث والدراسة، وإنتقلت من شخص لا يستطيع أن يقول حتى جملة مفيدة بالفرنسية إلى شخص يتكلم ويفكر بالفرنسية بشكل جيد وبدون مجهود. و أردت أن أشارك معكم الطريقة. </p>
            <br />
            <p className={"p-main" + pMainPhone}>وبالنسبة لسعر 100 درهم، فهو يعكس قيمة المجهود والوقت الذي قمت به لإنشاء هذه المنصة. و هو لا يساوي شيء مقارنة مع أثمنة الساعات الإضافية في مراكز تعلم اللغات.</p>
            <br />
            <p className={"p-main" + pMainPhone}>ماذا تنتظر تسجل الآن وابدأ الآن. لا تحتاج سوى هاتف أو حاسوب والأنترنت, وان لم يكن لديك الإنترنت بصفة دائمة يمكنك تحميل الكورس,.</p>
            <br /><br />
            <p className="end-text">♡ Bonne courage ♡</p>
        </div>
        <div className="flex align-items-center justify-center buy-btn-cover">
            <button onClick={handleLoginClick} className={"buy-btn" + buyBtnPhone}>الدخول إلى حسابي</button>
            <button onClick={handleWhatsAppClick}  className={"buy-btn" + buyBtnPhone}> (WhatsAPP) تواصل معنا </button>
            <button onClick={handleSignupClick} className={"buy-btn" + buyBtnPhone}>تسجل الآن</button>
        </div>
        <div className="h-20">
        </div>
    </div>
    
  );
}

export default Home;