import "./write.css";
import { useMediaQuery } from 'react-responsive';


function Write() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
      <h1 className="h1">الكتابة</h1>
      <p className="p">الكتابة هي عملية مهمة في تطوير مهارات التحدث، حيث تمنحك الفرصة للتفكير والتعبير بشكل دقيق ومنظم. كما تساعدك في تذكر الكلمات واستخدامها بطريقة فعالة. لذلك، يمكنك البدء في الكتابة منذ اليوم الأول لتحسين قدراتك في التحدث.</p>
      <h2 className="h2">ماذا وكيف أكتب؟</h2>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/r8a4b48LHdA?si=b4Davk7z3jv4iU_7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br /><br />
      <p>ملاحظة : يمكنك إستخدام ChatGpt لتطوير من كتاباتك. فمثلا تعطيه النص الذي كتبت وتقول له صحح لي أخطائي أنا مبتدئ، أو أي كلام تراه مناسب ...</p>
      <br /><br />
      <a href="https://drive.google.com/file/d/1KsGEPTHUvNs350rdKzjhvrv-bJsAl2Np/view?usp=drive_link">اضغط هنا لتحميل المواضيع التي يمكنك الكتابة فيها</a>
      <br /><br />
      <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
    </div>
  );
}

export default Write;