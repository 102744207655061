import "./start.css";
import { useMediaQuery } from 'react-responsive';


function Start() {
  const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
  const introPhone = isPhone? " intro-phone" : "";
  const mainVideoPhone = isPhone? " main-video-phone":"";
  return (
    <div className={"intro" + introPhone}>
      <h1 className="h1"> البداية  </h1>
      <br />
      <h2 className="h2">هل تعلم أننا تعلمنا لغتنا الأم بدون ترجمة؟</h2>
      <p className="p">
         فإذا كنت تعرف هذا، لماذا تتعلم اللغة الفرنسية بالترجمة؟ هل ترغب في أن تصبح مترجمًا؟ فإذا كنت تريد تعلم الفرنسية والتحدث بها والتفكير بها، فتعلمها بنفس الطريقة التي تعلمنا بها لغتنا الأم.
      </p>
      <h2 className="h2">كيف تعلمنا لغتنا الأم؟</h2>
      <p className="p">
      ببساطة، نحن نتعلم الأسماء من خلال الصورة. على سبيل المثال، عندما أقول لك "فيل"، فإنك تتخيل فورًا صورة فيل في عقلك دون الحاجة إلى الترجمة. أما بالنسبة للأفعال، فإننا نتعلمها من خلال الحركة. فعلى سبيل المثال، عندما أشير بيدي وأقول "اذهب"، فإنك تفهم فورًا أني أريدك أن تذهب، حتى لو لم أقل شيئًا. هكذا، يمكنك تعلم اللغة الفرنسية بنفس الطريقة التي تعلمنا بها لغتنا الأم.
        <br/><br/>
        فبهذه الطريقة سوف تتعلم معي الفرنسية الا أنه سوف تكون الترجمة في بعض الاحيان لتسهيل الفهم.
        <br/><br/>
        الان هيا لنتعلم الكلمات الأساسية في اللغة الفرنسية مع بعض الجمل : شاهد الفيديو اسفله
      </p>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/7mEsiD4r5QY?si=jOEBzb0F3dI3tdED" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <iframe className={mainVideoPhone} width="679" height="382" src="https://www.youtube.com/embed/0dvfIr3k-ac?si=xv3FeoDG6HD0mhdN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br />
      <a href="https://play.google.com/store/apps/details?id=com.ichi2.anki&pcampaignid=web_share">anki pour android</a>
      <br />
      <a href="https://apps.ankiweb.net/">anki pour laptop</a>
      <br /><br />
      <a href="https://drive.google.com/file/d/1b7C3z8Xa_lhr7_On_d08hPFPdd87p0Il/view?usp=sharing">اضغط هنا لتحميل أكثر من 1000 كلمة الأكثر إستعمال في الفرنسية لتبدأ بتعلمها </a>
      <br /><br />
      <a href={`https://wa.me/+212642366594?text=${encodeURIComponent('Bonjour, ')}`}>( اضغط هنا للتواصل معنا على WhatsApp)</a>
    </div>
  );
}

export default Start;